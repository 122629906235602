import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'

import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import QuoteCTA from '@components/Content/QuoteCTA/QuoteCTA'
import Section from '@components/Content/Section/Section'
import GatsbyHead from '@components/Layout/GatsbyHead'
import Layout from '@components/Layout/Layout'
import SEO from '@components/Layout/SEO'
import Wysiwyg from '@components/Layout/Wysiwyg'
import Video from '@components/Video/Video'

import { CarFinanceLandingPageProps } from '@contracts/carFinance'

import * as styles from './CarFinanceLandingPage.module.scss'

import caret from '@images/caret-right-pink.svg'

const CarFinanceLandingPage = (props: CarFinanceLandingPageProps) => {
	const [heroHeight, setHeroHeight] = React.useState(0)

	const heroRef = React.useRef<HTMLDivElement>(null)
	const PlayerRef = React.useRef(null)

	useEffect(() => {
		setHeroHeight(heroRef.current?.clientHeight || 0)
	}, [])

	const ScrollDown = () => {
		if (window) {
			window.scrollTo({
				top: heroHeight + 100,
				behavior: 'smooth',
			})
		}
	}

	const { data } = props.pageContext

	const Steps = () => {
		return data.template.howItWorksOptions.steps.map((step, i) => {
			return (
				<Section containerClassName={styles.step} key={`step=${i}`}>
					<div className={styles.left}>
						<span className={styles.count}>{i + 1}</span>
						{step.image.localFile && (
							<GatsbyImage image={step.image.localFile.childImageSharp.gatsbyImageData} alt="" />
						)}
					</div>
					<div className={styles.right}>
						<h2 className={styles.stepTitle}>{step.name}</h2>
						<Wysiwyg className={styles.content} content={step.subtext} />
					</div>
				</Section>
			)
		})
	}

	return (
		<Layout className={styles.layout}>
			<Section ref={heroRef} noPaddingBottom>
				<h1 className={styles.title}>{data.title}</h1>
				<Wysiwyg content={data.content} className={styles.subcontent} />

				<div className={styles.videoContainer}>
					<div className={styles.video}>
						<Video m3u8_url="/videos/FF-Animation.m3u8" mp4_url="/videos/FF-Animation.mp4" eager={true} />
					</div>

					<StaticImage
						placeholder="blurred"
						src="../images/astro/camper.png"
						alt=""
						className={styles.image}
						objectFit="contain"
					/>
					<StaticImage
						placeholder="blurred"
						src="../images/astro/biker.png"
						alt=""
						className={styles.image}
						objectFit="contain"
					/>
				</div>

				<div className={styles.arrowDownContainer}>
					<div className={styles.arrowDown} onClick={ScrollDown}>
						<img src={caret} alt="" className={styles.caret} />
					</div>
				</div>
			</Section>

			<Section>
				<div className={styles.steps}>{Steps()}</div>
			</Section>

			<QuoteCTA />
		</Layout>
	)
}

export function Head(props: CarFinanceLandingPageProps) {
	return (
		<>
			<GatsbyHead />
			<SEO data={props.pageContext.data.seo} />
		</>
	)
}

export default CarFinanceLandingPage
